import { createSlice } from "@reduxjs/toolkit";

// User Slice
const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: localStorage.getItem('user') ? true : false,
        user: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : {},
        orders: []
    },
    reducers: {
        // Login
        login: (state, action) => {
            state.status = true
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        // Register
        register: (state, action) => {
            state.status = true
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        // Logout
        logout: (state) => {
            state.status = false
            state.user = {}
            localStorage.removeItem("user");
        }
    }
})

const userReducer = userSlice.reducer
export default userReducer
