import { getImageUrlPath } from "../app/constants";

export function convertProducts(products) {
    return products.map(item => ({
        ...item,
        labels: "Trending",
        category: "fashion",
        img: getImageUrlPath(item?.image),
        hover_img: getImageUrlPath(item?.image),
        title: item?.name,
        description: item?.productDescription,
        rating: {
            rate: 3.9,
            count: 30,
        },
        color: []
    }))
}