
const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const ECOMMERCE_API = `${BASE_URL}ecommerce`;
export const ECOMMERCE_API_USER_ORDERS = `${ECOMMERCE_API}/userOrders`;
export const ECOMMERCE_LOGIN_API = `${ECOMMERCE_API}/login`
export const ECOMMERCE_REGISTER_API = `${ECOMMERCE_API}/register`

export const ECOMMERCE_PLACE_ORDER = `${ECOMMERCE_API}/createOrder`
export const ECOMMERCE_PAYMENT_SUCCESS = `${ECOMMERCE_API}/paymentSuccess`
export const ECOMMERCE_PAYMENT_CLOSE = `${ECOMMERCE_API}/paymentClose`
export const ECOMMERCE_USER_URL = `${ECOMMERCE_API}/users`;
export const ECOMMERCE_VERIFY_OTP = `${ECOMMERCE_API}/verifyOtp`;
export const ECOMMERCE_SEND_OTP = `${ECOMMERCE_API}/sendOtp`;
export const ECOMMERCE_UPDATE_USER_DETAILS = `${ECOMMERCE_API}/updateUser`;


export function getImageUrlPath(fileName) {
    return `${BASE_URL + 'retreiveFile/'}${fileName}`
}


export const HOME_URL = '/';
export const SHOP_URL = '/shop';
export const PRODUCT_DETAILS_URL = '/product-details';
export const WISHLIST_URL = '/wishlist';
export const CART_URL = '/cart';
export const CHECKOUT_URL = '/checkout';
export const LOGIN_URL = '/login';
export const REGISTER_URL = '/register';
export const ORDERS_URL = '/orders';


export const TERMS_AND_CONDITIONS_URL = 'https://merchant.razorpay.com/policy/P4VPVJFekhiQNc/terms';
export const REFUND_URL = 'https://merchant.razorpay.com/policy/P4VPVJFekhiQNc/refund';
export const SHIPPING_URL = 'https://merchant.razorpay.com/policy/P4VPVJFekhiQNc/shipping';

export const APP_NAME = 'Sports Gallery'

export const MOBILE = '+91-9597134978';
export const ADDRESS = '464/5,Near Pushpa Theater, Pn Road, Tirupur, 641602, Tamil Nadu';
export const EMAIL = 'sportsgallerytirupur@gmail.com';